import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  title,
  principleContainer,
  topContainer,
  description as descriptionClass,
  img as imageClass,
  titlePrinciples,
} from "./about-us.module.scss";

import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import Principle from "../components/molecules/principle";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

interface IAboutUsProps {
  className: string;
}

const AboutUsPage: React.FC<IAboutUsProps> = ({ data }) => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const {
    aboutUs: {
      description: {
        data: { description },
      },
      image: { localFile: image },
      principle: principles,
    },
  } = data;

  const bigImage = getImage(image);

  return (
    <MainLayout
      titleSeo={t("about.us.title")}
      descriptionSeo={t("about.us.title")}
    >
      <Title Tag="h1" size="large" align="left" className={title}>
        {t("about.us.title")}
      </Title>

      <div className={topContainer} data-aos="fade-left">
        <ReactMarkdown className={descriptionClass}>
          {description}
        </ReactMarkdown>
        {bigImage && (
          <GatsbyImage alt="" image={bigImage} className={imageClass} />
        )}
      </div>

      <Title Tag={"h4"} size={"large"} className={titlePrinciples}>
        {t("about.us.text")}
      </Title>

      <div className={principleContainer}>
        {principles.map((principle, index) => {
          const text = principle.text.data.text;
          const image = getImage(principle.image.localFile);

          return (
            <Principle
              image={image}
              title={principle.title}
              text={text}
              key={index}
            />
          );
        })}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    aboutUs: strapiAboutUs(locale: { eq: $language }) {
      principle {
        text {
          data {
            text
          }
        }
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      description {
        data {
          description
        }
      }
    }
  }
`;

export default AboutUsPage;
