import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  title as titleClass,
  text as textClass,
  top,
  imgContainer,
  img as imgClass,
  container,
} from "./principle.module.scss";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

interface IPrincipleProps {
  className?: string;
  title?: string;
  text?: string;
  image?: IGatsbyImageData | undefined;
}

const Principle: React.FC<IPrincipleProps> = ({
  className = "",
  image,
  text,
  title,
}) => {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <div className={`${className} ${container}`} data-aos="fade-left">
      <div className={top}>
        <div className={imgContainer}>
          {image && <GatsbyImage alt="" image={image} className={imgClass} />}
        </div>

        <h4 className={titleClass}>{title}</h4>
      </div>

      <p className={textClass}>{text}</p>
    </div>
  );
};

export default Principle;
